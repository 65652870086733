import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import CardIcon from '../CardIcon';
import Modal, { ModalProps } from '../Modal'; // Adjust the path as necessary
import ModalActions from '../ModalActions';
import ModalContent from '../ModalContent';
import ModalTitle from '../ModalTitle';

const StyledParagraph = styled.p`
  color: #fff; // White color for text
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 15px;
  text-align: left; // Align text to the left
`;

interface DisclaimerModalProps extends ModalProps {
  onConfirm: () => void;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({
  onConfirm,
  onDismiss,
}) => {
  const [step, setStep] = useState('disclaimer');

  const handleConfirm = useCallback(() => {
    onConfirm();
    onDismiss();
  }, [onConfirm, onDismiss]);

  const modalContent = useMemo(() => {
    if (step === 'disclaimer') {
      return (
        <div>
          <StyledParagraph>DRAGON Token and Farm Deployment Update</StyledParagraph>
          <StyledParagraph>
            We are excited to announce that the DRAGON token contract and the yield farm
            have been successfully deployed on the BLAST L2 network and now on BASE. The farm, however,
            will commence operations only after reaching a milestone of 100 $DRAGON token holders.
          </StyledParagraph>
          <StyledParagraph>
            This approach ensures a fair start and gives our community ample time to prepare
            for the official launch. We invite you to join us and be part of this exciting journey.
          </StyledParagraph>
        </div>
      );
    } else {
      return (
        <div>
          <StyledParagraph>Important Notice for DRAGON Token Holders</StyledParagraph>
          <StyledParagraph>
            As we gear up for the launch of our innovative yield farm, we remind you that the
            farm's activation is contingent on achieving 100 $DRAGON token holders. Your support
            and participation are vital in unlocking the full potential of our platform.
          </StyledParagraph>
        </div>
      );
    }
  }, [step]);

  const button = useMemo(() => {
    if (step === 'disclaimer') {
      return (
        <Button
          text="Next"
          variant="secondary"
          onClick={() => setStep('uniswap')}
        />
      );
    } else {
      return <Button text="I understand" onClick={handleConfirm} />;
    }
  }, [setStep, step, handleConfirm]);

  return (
    <Modal>
      <ModalTitle text="Welcome to Dragon24" />
      <CardIcon>🐉</CardIcon>
      <ModalContent>{modalContent}</ModalContent>
      <ModalActions>{button}</ModalActions>
    </Modal>
  );
};

export default DisclaimerModal;
