export const white = '#EFEFEF'; // A slightly off-white for a more muted look
export const black = '#000'; // Pure black remains
export const blacktransparent = 'rgba(0, 0, 0, 0.8)'; // More opaque for a darker feel

export const orange = {

  500: '#FF4500', // Bright orange-red
  600: '#FF6347', // Tomato red
  700: '#FF8C00', // Dark orange
  800: '#FFA500', // Vibrant orange
};

export const red = {
  100: '#FFEBEB', // Very light red, almost pink
  200: '#FF6A6A', // Bright, attention-grabbing red
  500: '#CC0000', // Classic, deep red
  600: '#A30000', // Darker, more intense red
  1000: '#7E0000', // Even darker for a serious tone
  1100: '#F31D1D', // Bright and alarming
  1150: '#E50000', // Slightly darker, intense red
  1200: '#BF0000', // Dark, blood-like red
};

export const grey = {
  100: '#EBEBEB', // Light grey
  200: '#D1D1D1', // Medium light grey
  300: '#A8A8A8', // Neutral grey
  400: '#7F7F7F', // Medium dark grey
  500: '#565656', // Darker grey
  600: '#2E2E2E', // Very dark grey
  800: '#1C1C1C', // Near black grey
  900: '#1A1A1A', // Slightly darker
  1000: '#0F0F0F', // Almost black
  1001: '#0D0D0D', // Darker
  1100: '#0B0B0B', // Even darker
  1110: '#080808', // Almost pure black
  1150: '#060606', // Dark, shadowy grey
  1200: '#040404', // Near pure black
};

export const blue = {
  200: '#1F7FAD', // A deeper, more serious blue
  500: '#122F5E', // Dark midnight blue
  800: '#0D1B33', // Even darker for depth
  1000: '#0A1729', // Near black blue
  1100: '#08122D', // Darkest blue
};
