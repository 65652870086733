import React from 'react'
import styled from 'styled-components'

interface CardIconProps {
  children?: React.ReactNode
}

const CardIcon: React.FC<CardIconProps> = ({ children }) => (
  <StyledCardIcon>{children}</StyledCardIcon>
)

const StyledCardIcon = styled.div`
  font-size: 36px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto ${(props) => props.theme.spacing[3]}px;
  @media (max-width: 400px) {
    margin: 0;
    padding: 0;
  }
`

export default CardIcon
