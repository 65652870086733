import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Home from '../../../assets/images/header/homemenu.png';
import RVLab from '../../../assets/images/header/rvlabmenu.png';
import Dragonsafe from '../../../assets/images/header/superlabmenu.png';
import Nft from '../../../assets/images/header/nftmenu.png';
import Exchange from '../../../assets/images/header/exchange.png';
import Eth from '../../../assets/images/header/eth.png';

const Nav: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const handleSetMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <StyledNav>
      <StyledMobileNav onClick={handleSetMobileMenu}>
        <StyledMobileNavItem />
        <StyledMobileNavItem />
        <StyledMobileNavItem />
      </StyledMobileNav>
      <StyledMenu className={mobileMenu ? 'active_mobilemenu' : null}>
        <StyledMenuItem>
          <StyledLink exact activeClassName="active" to="/">
            <MenuImage src={Home} />
          </StyledLink>
        </StyledMenuItem>
        <StyledMenuItem>
          <StyledLink exact to="/vault">
            <MenuImage src={Exchange} />
          </StyledLink>
        </StyledMenuItem>
        <StyledMenuItem>
          <StyledLink exact to="/farm">
            <MenuImage src={RVLab} />
          </StyledLink>
        </StyledMenuItem>
        <StyledMenuItem>
          <StyledLink exact to="/vault">
            <MenuImage src={Dragonsafe} />
          </StyledLink>
        </StyledMenuItem>
      </StyledMenu>
    </StyledNav>
  );
};

const StyledMobileNav = styled.nav`
  position: absolute;
  top: 20px;
  left: 15px;
  width: 25px;
  cursor: pointer;
  display: none;
  z-index: 2000;
  @media (max-width: 992px) {
    display: block;
  }
`;

const StyledMobileNavItem = styled.div`
  background: ${(props) => props.theme.color.white};
  opacity: 0.8;
  height: 3px;
  width: 25px;
  margin: 5px auto;
  transition: all 300ms;
`;

const StyledNav = styled.nav`
  flex: 1;
  max-width: 70%;
  margin-top: 0;
  padding-top: 0;
  z-index: 1000;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const StyledMenu = styled.ul`
  float: right;
  margin-right: 15px;
  z-index: 100;
  @media (max-width: 992px) {
    display: none;
  }
`;

const StyledMenuItem = styled.li`
  float: left;
  margin: 13px 15px 0 15px;
  @media (max-width: 992px) {
    float: none;
    padding: 5px;
  }
`;
const MenuImage = styled.img``

const StyledLink = styled(NavLink)``

export default Nav
