import BigNumber from 'bignumber.js'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import Value2 from '../../../components/Value2'
import useAllEarnings from '../../../hooks/useAllEarnings'
import useAllStakedValue from '../../../hooks/useAllStakedValue'
import useFarms from '../../../hooks/useFarms'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useSushi from '../../../hooks/useSushi'
import { getSushiAddress, getEthToBtcValue, getSushiSupply } from '../../../sushi/utils'
import { getBalanceNumber } from '../../../utils/formatBalance'
import cardBackground from '../../../assets/images/home/bgcard.png';

import Container from '../../../components/Container'


import { addressMap } from '../../../sushi/lib/constants'
 // Initiate the CoinGecko API Client

const lookUpTokenPrices = async function(id_array: any) {
  let ids = id_array.join('%2C')
  return $.ajax({
    url: 'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=' + ids + '&vs_currencies=usd',
    type: 'GET',
  })
}

const PendingRewards: React.FC = () => {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)
  const [scale, setScale] = useState(1)

  const allEarnings = useAllEarnings()
  let sumEarning = 0
  for (let earning of allEarnings) {
    sumEarning += new BigNumber(earning)
      .div(new BigNumber(10).pow(18))
      .toNumber()
  }

  useEffect(() => {
    setStart(end)
    setEnd(sumEarning)
  }, [sumEarning])

  return (
    <span
      style={{
        transform: `scale(${scale})`,
        transformOrigin: 'right bottom',
        transition: 'transform 0.5s',
        display: 'inline-block',
      }}
    >
      <CountUp
        start={start}
        end={end}
        decimals={end < 0 ? 4 : end > 1e5 ? 0 : 3}
        duration={1}
        onStart={() => {
          setScale(1.25)
          setTimeout(() => setScale(1), 600)
        }}
        separator=","
      />
    </span>
  )
}

const Balances: React.FC = () => {
  const [totalSupply, setTotalSupply] = useState<BigNumber>()
  const sushi = useSushi()
  const sushiBalance = useTokenBalance(getSushiAddress(sushi))
  const { account, ethereum }: { account: any; ethereum: any } = useWallet()

  const [farms] = useFarms()
  const allStakedValue = useAllStakedValue()

  let sumWeth = 0, dragonPerBlock = 0, dragonPrice = 0;
  if (allStakedValue && allStakedValue.length) {
    dragonPerBlock = allStakedValue[0].dragonPerBlock
    sumWeth = farms.reduce(
      (c, { id }, i) => c + (allStakedValue[i].totalWethValue.toNumber() * allStakedValue[i].ethPrice || 0),
      0,
    )
  }

  useEffect(() => {
    async function fetchTotalSupply() {
      const supply = await getSushiSupply(sushi)
      setTotalSupply(supply)
    }
    if (sushi) {
      fetchTotalSupply()
    }
  }, [sushi, setTotalSupply])

  return (
    <StyledWrapper>
    <Container>
      <Cardswrapper>
        <CardItem>
          <StyleCardContentTitle>Your Balance</StyleCardContentTitle>
          <Value2
            value={!!account ? getBalanceNumber(sushiBalance).toFixed(2) + ' DRAGON' : 'LOCKED'}
          />
          <StyleCardContent>
            Pending harvest: <PendingRewards />
          </StyleCardContent>
        </CardItem>

        <CardItem>
          <StyleCardContentTitle>Total Locked</StyleCardContentTitle>
          <Value2 value={sumWeth.toFixed(2) + " $"} />
          <StyleCardContent>Total Locked</StyleCardContent>
        </CardItem>

        <CardItem>
          <StyleCardContentTitle>$DRAGON</StyleCardContentTitle>
          <Value2 value={dragonPrice != 0 ? dragonPrice.toFixed(2) + " $" : "COMING SOON"} />
          <StyleCardContent>$DRAGON Price</StyleCardContent>
        </CardItem>

        <CardItem>
          <StyleCardContentTitle>Circulating</StyleCardContentTitle>
          <Value2
            value={totalSupply ? getBalanceNumber(totalSupply).toFixed(2) + ' DRAGON' : 'LOCKED'}
          />
          <StyleCardContent>Reward: APY</StyleCardContent>
        </CardItem>
      </Cardswrapper>
    </Container>
  </StyledWrapper>

  )
}
const StyleCardContentTitle = styled.h4`
  font-size: 1.8rem;
  color: ${props => props.theme.color.white};
  margin-bottom: 10px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Adding shadow
`;


const CardItem = styled.div`
box-shadow: 0px 4px 10px ${props => props.theme.color.orange[800]};
background: url(${cardBackground}) no-repeat center center;
background-size: cover; // This ensures the background covers the entire card
border: 2px solid ${props => props.theme.color.white};
  margin: 15px;
  min-width: 22%; // Adjust for four cards side-by-side
  max-width: 22%; // Adjust for four cards side-by-side
  min-height: 200px;
  padding: 15px 20px;
  text-align: center;
  position: relative;
  color: ${props => props.theme.color.white};
  border-radius: 0; // Making corners square
  transition: transform 0.3s ease, box-shadow 0.5s ease;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 6px 15px ${props => props.theme.color.orange[900]};
  }

  @media (max-width: 1024px) {
    min-width: 45%; // Adjust for medium-sized screens
    max-width: 45%; // Adjust for medium-sized screens
  }

  @media (max-width: 768px) {
    min-width: 100%; // Full width for smaller screens
    max-width: 100%; // Full width for smaller screens
  }
`;

const Cardswrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Center cards when they don't fill the full width
  align-items: stretch; // Align items vertically
  margin-bottom: 70px;
`;


const StyledWrapper = styled.section``
const StyleCardImage = styled.img`
  max-width: 100%;
`

const StyleCardContent = styled.h5`
  position: absolute;
  bottom: 20px;
  font-size: 1.4rem;
  width: 100%;
  left: 0;
  font-weight: normal;
  padding: 0 25px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Adding shadow
`;


export default Balances
