import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { UseWalletProvider } from 'use-wallet';
import DisclaimerModal from './components/DisclaimerModal';
import FarmsProvider from './contexts/Farms';
import ModalsProvider from './contexts/Modals';
import TransactionProvider from './contexts/Transactions';
import SushiProvider from './contexts/SushiProvider';
import useModal from './hooks/useModal';
import theme from './theme';
import Farms from './views/Farms';
import Home from './views/Home';
import Fonts from './assets/fonts';
import Nft from './views/Nft';
import Dragonsafe from './views/Dragonsafe';

const TopBarContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    background-color: #000;
    padding: 10px;
    z-index: 5000;
    width: 100%; // Ensure full width
    margin: 0; // Reset any default margins
  }
`;
const ChainSelectContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1000;

  @media (min-width: 1025px) {
    // For desktops and larger screens
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @media (max-width: 1024px) {
    // For tablets, mobile devices, and smaller screens
    position: relative;
    top: initial;
    right: initial;
    left: initial;
    justify-content: center;
    background-color: #000;
    width: 100%;
    padding: 10px 0;
    margin-top: 0;
  }
`;

const ChainSelect = styled.select`
  padding: 8px 12px;
  color: #FFF; // White text for contrast
  background-color: #000; // Black background
  border: 2px solid #FF8C00; // Dark orange border for a pop of color
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px #FF8C00; // Orange shadow for some flair
  transition: all 0.3s ease;
  animation: flashAnimation 1.5s infinite alternate;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 10px;
  }

  option {
    background-color: #000; // Black background for options
    color: #FFF; // White text for options
  }

  &:hover, &:focus {
    background-color: #333; // Slightly lighter black on hover/focus
    box-shadow: 0 0 15px #FFA500; // Intensify the shadow on hover/focus
  }

  @keyframes flashAnimation {
    from {
      box-shadow: 0 0 10px #FF8C00;
    }
    to {
      box-shadow: 0 0 15px #FFA500;
    }
  }
`;

const ChainOption = styled.option``;
const App: React.FC = () => {
  const [currentChainId, setCurrentChainId] = useState<string>('');

  const handleChainChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentChainId(event.target.value);
  };

  return (
    <Router>
      <ChainSelectContainer>
        <ChainSelect onChange={handleChainChange} value={currentChainId || ''}>
          <option value="">SELECT NETWORK</option>
          <option value="8453">BASE</option>
          <option value="81457">BLAST</option>
        </ChainSelect>
      </ChainSelectContainer>
      <Providers currentChainId={parseInt(currentChainId, 10) || 0}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/farm" component={Farms} />
          <Route path="/vault" component={Dragonsafe} />
          {/* Other routes */}
        </Switch>
        <Disclaimer />
      </Providers>
    </Router>
  );
};

const Providers: React.FC<{ currentChainId: number }> = ({ children, currentChainId }) => {
  const rpcUrls = {
    81457: 'https://rpc.ankr.com/blast',
    8453: 'https://mainnet.base.org'
  };

  const rpcUrl = rpcUrls[currentChainId] || '';

  useEffect(() => {
    // Define an async function
    const initializeConnection = async () => {
      if (window.ethereum) {
        try {
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          const parsedChainId = parseInt(chainId, 16);
          if (parsedChainId !== currentChainId) {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: `0x${currentChainId.toString(16)}` }],
            });
          }
        } catch (error) {
          console.error('Error initializing connection:', error);
        }
      }
    };

    // Call the async function
    initializeConnection();
  }, [currentChainId]);

  return (
    <ThemeProvider theme={theme}>
      <Fonts />
      <UseWalletProvider
        chainId={currentChainId}
        connectors={{
          walletconnect: { rpcUrl: rpcUrl }
        }}
      >
        <SushiProvider>
          <TransactionProvider>
            <FarmsProvider>
              <ModalsProvider>{children}</ModalsProvider>
            </FarmsProvider>
          </TransactionProvider>
        </SushiProvider>
      </UseWalletProvider>
    </ThemeProvider>
  );
};

const Disclaimer: React.FC = () => {
  const markSeen = useCallback(() => {
    localStorage.setItem('disclaimer', 'seen')
  }, [])

  const [onPresentDisclaimerModal] = useModal(
    <DisclaimerModal onConfirm={markSeen} />,
  )

  useEffect(() => {
    const seenDisclaimer = true // localStorage.getItem('disclaimer')
    if (!seenDisclaimer) {
      onPresentDisclaimerModal()
    }
  }, [])

  return <div />
}

export default App
