import React from 'react';
import styled from 'styled-components';
import Discord from '../../../assets/images/footer/Discord.png';
import Telegram from '../../../assets/images/footer/Telegram.png';
import Twitter from '../../../assets/images/footer/Twitter.png';

const Nav: React.FC = () => {
  return (
    <StyledFooter>
      <StyledFooterContent>
        <StyledSection>
          <SectionTitle>Disclaimer</SectionTitle>
          <SectionText><strong>Use at your own risk.</strong> The DRAGON token farm is an experimental and evolving project.
                  We endeavor to ensure reliability and security, but please be aware that <strong>we cannot
                  guarantee against all risks.</strong> Users are encouraged to exercise caution and conduct
                  thorough research to understand the <strong>nature and potential risks involved.</strong></SectionText>
        </StyledSection>
        <StyledSectionGroup>
        <StyledSectionRight>
          <SectionTitleRight>Explore Our Contracts</SectionTitleRight>
          <ContractLinks>
            <a href="https://basescan.org/address/0x82bbbcbd6df79d89532c1de0a9a0bda2bbf58202" target="_blank" rel="noopener noreferrer">BASE DRAGON Token</a>
            <a href="https://blastscan.io/address/0x82bbbcbd6df79d89532c1de0a9a0bda2bbf58202" target="_blank" rel="noopener noreferrer">BLAST L2 DRAGON Token</a>
          </ContractLinks>
        </StyledSectionRight>
        </StyledSectionGroup>
      </StyledFooterContent>
      <StyledCopyright>
        <StyledSocialLinks>
          <SocialLink target="_blank" href="https://discord.gg/fUFFv4tCVj">
            <img src={Discord} alt="Discord" />
          </SocialLink>
          <SocialLink target="_blank" href="https://t.me/dragonb24">
            <img src={Telegram} alt="Telegram" />
          </SocialLink>
          <SocialLink target="_blank" href="https://twitter.com/dragon24b">
            <img src={Twitter} alt="Twitter" />
          </SocialLink>
        </StyledSocialLinks>
        © {new Date().getFullYear()} Dragon24. All rights reserved.<br />
      </StyledCopyright>
       <StyledVersion>v1.0.4</StyledVersion>
    </StyledFooter>
  );
};
const StyledVersion = styled.div`
  color: #FFF; // White color for text
  font-size: 0.8rem; // Smaller font size for subtlety
  text-align: center; // Center the text
  margin-top: 10px; // Space from the top
  opacity: 0.7; // Slightly transparent for a softer appearance

  &:before {
    content: 'Version ';
  }
`;
const StyledFooter = styled.footer`
  background-color: transparent;
  color: #FFF;
  padding: 50px 0;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7); // Adding a dark glow effect to the text
`;


const StyledFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledSectionGroup = styled.div`
  flex: 0 0 calc(66.666% - 20px);
  max-width: calc(66.666% - 20px);
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    order: 2; // Move this section below the disclaimer on mobile
  }
`;

const StyledSection = styled.div`
  flex: 0 0 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    order: 1; // Ensure this section comes first on mobile
  }
`;

const StyledSectionRight = styled(StyledSection)`
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
const SectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #FF8C00;
  margin-bottom: 10px;
`;

const SectionTitleRight = styled(SectionTitle)`
  text-align: right;
`;

const SectionText = styled.p`
  font-size: 1rem;
  margin: 0;
`;

const ContractLinks = styled.div`
  a {
    color: #FFA500;
    text-decoration: none;
    display: block;
    margin-top: 5px;
    &:hover {
      color: #FFD700;
    }
  }
`;

const StyledSocialLinks = styled.div`
  display: flex;
  justify-content: flex-end; /* Align to the right on larger screens */

  @media (max-width: 768px) {
    justify-content: center; /* Center the links on smaller screens */
    flex-wrap: wrap; /* Allows the items to wrap if needed */
  }
`;
const SocialLink = styled.a`
  margin: 0 15px;

  @media (max-width: 768px) {
    margin: 5px; /* Reduce margin on smaller screens */
  }
`;

const StyledDisclaimer = styled.div`
  color: #ffa07a;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  margin-top: 30px;
`;

const StyledCopyright = styled.div`
  font-size: 0.9rem;
  text-align: center;
`;

export default Nav;
