import { black, orange, blue, grey, white, blacktransparent } from './colors';

const theme = {
  borderRadius: 12,
  breakpoints: {
    mobile: 400,
  },
  color: {
    black,
    grey,
    orange, // Added orange as a primary color category
    blacktransparent,
    blue,
    // Changed primary and secondary colors to shades of orange
    primary: {
      light: orange[700], // Using dark orange
      main: orange[800], // Using vibrant orange
    },
    secondary: {
      main: orange[500], // Using bright orange-red
    },
    white,
  },
  siteWidth: 1800,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72,
};

export default theme;
