import BigNumber from 'bignumber.js/bignumber'
import React from 'react'
import Sushi from '../../assets/images/farm/sushi.png'
import Grt from '../../assets/images/farm/grt.png'
import Uni from '../../assets/images/farm/uni.png'
import Wbtc from '../../assets/images/farm/wbtc.png'
import Usdt from '../../assets/images/farm/usdt.png'
import DRAGON from '../../assets/images/farm/dragon-mim.png'
import DRAGONWETH from '../../assets/images/farm/dragon-weth.png'
import DRAGONWETHB from '../../assets/images/farm/dragon-wethb.png'
import DRAGONUSDB from '../../assets/images/farm/dragon-usdb.png'

export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935',
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const addressMap = {
  uniswapFactory: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  uniswapFactoryV2: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  YFI: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  YCRV: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
  UNIAmpl: '0xc5be99a02c6857f9eac67bbce58df5572498f40c',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  UNIRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  LINK: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  MKR: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  SNX: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
  COMP: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
  LEND: '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
  SUSHIYCRV: '0x2C7a51A357d5739C5C74Bf3C96816849d2c9F726',
  Uniswap: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
}

// SETTINGS

export const CHAIN_ID = [81457, 8453];

export const contractAddresses = {
  sushi: {
    81457: '0x82BBBcbD6df79d89532C1De0a9A0bda2Bbf58202', //DRAGON Token Contract
    8453: '0x82BBBcbD6df79d89532C1De0a9A0bda2Bbf58202', //DRAGON Token Contract
  },
  masterChef: {
    81457: '0xCbB8d831F8d47A677d59346F474Ae544a5c040bF', //DRAGONFARM
    8453: '0x52Ca7cE9ADB1371f72d2F100C59f4e87a8b27262', //DRAGONFARM
  },
  weth: {
    81457: '0x4300000000000000000000000000000000000004', //WETH
    8453: '0x4200000000000000000000000000000000000006', //WETH
  },
  xSushi: {
    1: '0x8798249c2e607446efb7ad49ec89dd1865ff4272' // NOTE: Not in use
  }
}

export const supportedPools = [
    {
    pid: 0,
    lpAddresses: {
      8453: '0x5d668095F40D36A871C92a57EeAd3CFF97EA1271',
    },
    tokenAddresses: {
      8453: '0x82BBBcbD6df79d89532C1De0a9A0bda2Bbf58202',
    },
    name: 'DRAGON-ETH',
    symbol: 'UNI-V2',
    tokenSymbol: 'DRAGON',
    icon: <img alt="DRAGONeth" src={DRAGONWETH} />,
    pool: 'uniswap',
    uniswapLPUrl:
      'https://app.uniswap.org/add/v2/ETH/0x82BBBcbD6df79d89532C1De0a9A0bda2Bbf58202',
  },

]
