import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useWallet } from 'use-wallet';
import metamaskLogo from '../../assets/images/wallet/metamask.png';
import walletConnectLogo from '../../assets/images/wallet/walletconnect.png';
import Modal, { ModalProps } from '../Modal';
import ModalTitle from '../ModalTitle';

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, connect } = useWallet();

  useEffect(() => {
    if (account) {
      onDismiss();
    }
  }, [account, onDismiss]);

  const handleConnectMetamask = () => connect('injected');
  const handleConnectWalletConnect = () => connect('walletconnect');

  return (
    <StyledModal>
    <CloseButton onClick={onDismiss}>
      Close
    </CloseButton>
      <ModalTitle text="Select a Wallet Provider" />
      <ModalContent>
        <StyledWalletCard onClick={handleConnectMetamask}>
          <WalletIcon alt="Metamask" src={metamaskLogo} />
          <WalletTitle>Metamask</WalletTitle>
        </StyledWalletCard>

        <StyledWalletCard onClick={handleConnectWalletConnect}>
          <WalletIcon alt="WalletConnect" src={walletConnectLogo} />
          <WalletTitle>WalletConnect</WalletTitle>
        </StyledWalletCard>
      </ModalContent>
      <Paragraph>
  If you can't connect, please ensure you are on the correct network.
</Paragraph>
    </StyledModal>
  );
};

const Paragraph = styled.p`
  color: #666;
  font-size: 14px;
  margin-top: 10px;
`;
const StyledModal = styled(Modal)`
  position: relative;
  z-index: 10; // Higher than StyledTicker to ensure it appears above
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const StyledWalletCard = styled.div`
  flex-basis: calc(50% - 20px);
  margin: 10px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1); // Semi-transparent white background
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.2); // Slightly more opaque on hover
  }
`;


const WalletIcon = styled.img`
  height: auto;
  max-width: 80%;
  padding: 10px;
`;

const WalletTitle = styled.div`
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF; // White text color
  font-size: 1.2rem; // Adjust the font size as needed
  &:hover {
    opacity: 0.8;
  }
`;

export default WalletProviderModal;
