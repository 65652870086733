import React from 'react'
import styled from 'styled-components'
import LogoImage from '../../assets/images/home/logo.png'
import Page from '../../components/Page'
import Balances from './components/Balances'
import Background from '../../assets/images/home/homebg.jpg'
// Component for buying DRAGON tokens

const Home: React.FC = () => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Contract address copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };
  return (
    <Page background={Background}>
    <StyledTicker>
        <ScrollText>
          🌐 PLEASE SELECT NETWORK THEN UNLOCK WALLET 🚀  🔵 BASE FARM NOW OPEN - 🌱 BLAST L2 FARM NOT OPEN🌱
        </ScrollText>
      </StyledTicker>
      <StyledLogo>
        <StyledInteractiveLogo src={LogoImage} />

        <StyledAnnouncement>
        <AnnouncementTitle>Experience the Frontier of Yield Farming with DRAGON</AnnouncementTitle>
        <AnnouncementContent>
          Harness the power of innovation in DeFi with DRAGON's unique yield farming platform on BASE / BLAST networks. Our approach redefines digital asset management, blending novel strategies with user empowerment. Join a community where the future of yield farming is being rewritten.
        </AnnouncementContent>
        <StyledBuyToken href="https://app.uniswap.org/swap?chainId=8453&inputCurrency=0x82BBBcbD6df79d89532C1De0a9A0bda2Bbf58202">
          $DRAGON BASE from Uniswap🦄
        </StyledBuyToken>
  <StyledBuyToken href="https://www.sushi.com/swap?chainId=81457&token0=NATIVE&token1=0x82BBBcbD6df79d89532C1De0a9A0bda2Bbf58202">
    $DRAGON BLAST L2 from Sushi🍣
  </StyledBuyToken>
  <StyledContractAddress onClick={() => copyToClipboard("0x82BBBcbD6df79d89532C1De0a9A0bda2Bbf58202")}>
    $DRAGON Token Contract address [click to copy]
  </StyledContractAddress>
</StyledAnnouncement>
      </StyledLogo>
<Balances />
      <StyledTokenomicsSection>
  <StyledTokenomicsTitle>DRAGON Tokenomics: A Comprehensive Outline 🐉</StyledTokenomicsTitle>
  <StyledTokenomicsContent>
    <TokenomicItem>
      <TokenomicItemTitle>Initial Minted Supply</TokenomicItemTitle>
      <TokenomicItemContent>1 million DRAGON tokens✔️</TokenomicItemContent>
    </TokenomicItem>
    <TokenomicItem>
      <TokenomicItemTitle>Ownership Transfer</TokenomicItemTitle>
      <TokenomicItemContent>DragonFarm contract✔️</TokenomicItemContent>
    </TokenomicItem>
    <TokenomicItem>
      <TokenomicItemTitle>Minting Authority</TokenomicItemTitle>
      <TokenomicItemContent>Only DRAGONFARM can mint✔️</TokenomicItemContent>
    </TokenomicItem>
    <TokenomicItem>
      <TokenomicItemTitle>Maximum Supply</TokenomicItemTitle>
      <TokenomicItemContent>2.4 billion DRAGON tokens</TokenomicItemContent>
    </TokenomicItem>
    <TokenomicItem>
      <TokenomicItemTitle>Reward Rate</TokenomicItemTitle>
      <TokenomicItemContent>~30 DRAGON tokens/min</TokenomicItemContent>
    </TokenomicItem>
    <TokenomicItem>
      <TokenomicItemTitle>Deployment</TokenomicItemTitle>
      <TokenomicItemContent>Base & Blast L2✔️</TokenomicItemContent>
    </TokenomicItem>
    <TokenomicItem>
    <TokenomicItemTitle>Farming Fee</TokenomicItemTitle>
    <TokenomicItemContent>5% fee on entry to DRAGONFARM</TokenomicItemContent>
  </TokenomicItem>
  <TokenomicItem>
    <TokenomicItemTitle>Locked liquidity</TokenomicItemTitle>
    <TokenomicItemContent>Base & Blast L2✔️</TokenomicItemContent>
  </TokenomicItem>


  </StyledTokenomicsContent>
</StyledTokenomicsSection>

<StyledMore>
<p>
     This is an exciting threshold that will unlock the full potential of our DeFi ecosystem on the Base & Blast L2 Network. Be one of the pioneering holders and get ready to maximize your yield farming experience!
</p>
</StyledMore>
    </Page>
  )
}
const StyledTicker = styled.div`
  width: 100%;
  background-color: #000; // Black background
  color: white;
  overflow: hidden; // Hide the overflow
  white-space: nowrap; // Keep text in a single line
  position: relative; // Ensure this is relative
  z-index: 2; // Keep it lower than the modal

  @media (max-width: 768px) {
    z-index: 2; // Ensure this z-index is consistent and lower than the modal
  }
`;

const ScrollText = styled.div`
  display: inline-block;
  padding-left: 100%; // Start off the screen
  animation: ticker 20s linear infinite;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8); // Dark glow effect

  @keyframes ticker {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
`;

const StyledTokenomicsSection = styled.section`
  padding: 20px;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(33, 33, 33, 0.8));
  color: #FFF;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
  margin: 20px auto;
  max-width: 80%;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
    padding: 20px 10px;
  }
`;

const StyledTokenomicsTitle = styled.h2`
  color: #FF8C00;
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const StyledTokenomicsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const TokenomicItem = styled.div`
  background: linear-gradient(45deg, #FF8C00, #FFA500);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const TokenomicItemTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #000;
`;

const TokenomicItemContent = styled.p`
  font-size: 1.1rem;
  color: #000;
`;

const StyledInteractiveLogo = styled.img`
  width: 400px;
  max-width: 100%;
  z-index: 1; // Lower than StyledMenu
  animation: logoFloat 6s ease-in-out infinite;
  @keyframes logoFloat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
`

const StyledAnnouncement = styled.div`
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(33, 33, 33, 0.8));
  color: #FFF;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
  margin: 20px auto;
  max-width: 80%;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
    padding: 20px 10px;
  }
`;

const AnnouncementTitle = styled.h2`
  color: #FF8C00;
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const AnnouncementContent = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 30px;
`;

const StyledBuyToken = styled.a`
  display: inline-block;
  margin: 30px 10px;
  background: linear-gradient(45deg, #FF8C00, #FFA500);
  color: #fff;
  font-weight: bold;
  font-size: 1.9rem;
  padding: 18px 35px;
  border-radius: 12px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #FFA500, #FFD700);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transform: translateY(-5px);
  }
`;

const StyledContractAddress = styled.p`
  font-size: 0.9rem;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #FFD700;
  }
`;

const StyledLogo = styled.section`
  text-align: center;
  margin: 50px auto 30px auto;
  padding: 10px;
  min-height: 270px;
  @media (max-width: 420px) {
    min-height: unset;
  }
`
const StyledLogoImage = styled.img`
  width: 400px;
  max-width: 100%;
`
const StyledFarmHeader = styled.div`
  text-align: center;
  margin-top: 30px;
`;


const StyledFarmtitle = styled.h2`
  color: ${(props) => props.theme.color.white};
  font-weight: bold;
  font-size: 2.5rem;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 20px 0;
  padding: 10px 20px;
  background-image: linear-gradient(45deg, ${(props) => props.theme.color.orange[500]}, ${(props) => props.theme.color.orange[700]});
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  display: inline-block;
  position: relative;
  animation: funBounce 1s ease infinite;
  transform-origin: center bottom;

  @keyframes funBounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  &:hover {
    background-image: linear-gradient(45deg, ${(props) => props.theme.color.orange[700]}, ${(props) => props.theme.color.orange[800]});
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`
const StyledMore = styled.div`
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(33, 33, 33, 0.8));
  color: #FFF;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
  margin: 20px auto;
  max-width: 80%;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
    padding: 20px 10px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
  }
`;

export default Home
