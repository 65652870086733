import React, { useCallback } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useSushi from '../../../hooks/useSushi'
import { getSushiAddress } from '../../../sushi/utils'
import { getBalanceNumber } from '../../../utils/formatBalance'
import Modal, { ModalProps } from '../../Modal'
import Button from '../../../components/Button/Button'
import Background from '../../assets/images/home/homebg.jpg';

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, reset } = useWallet()

  const handleSignOutClick = useCallback(() => {
    onDismiss!()
    reset()
  }, [onDismiss, reset])

  const sushi = useSushi()
  const sushiBalance = useTokenBalance(getSushiAddress(sushi))

  return (
    <StyledModal>
    <CloseButton onClick={onDismiss}>
      Close
    </CloseButton>
      <Title>My Account</Title>
      <Balance>
        {getBalanceNumber(sushiBalance)}
        <br /> DRAGON Balance
      </Balance>
      <StyledAnchor href={`https://blastscan.io/address/${account}`} target="_blank" rel="noopener noreferrer">
        View Account
      </StyledAnchor>
      <Button onClick={handleSignOutClick}>Sign Out</Button>
    </StyledModal>
  )
}
const StyledAnchor = styled.a`
display: block;
border: 2px solid ${(props) => props.theme.color.white};
padding: 10px;
color: ${(props) => props.theme.color.white};
text-align: center;
text-decoration: none;
font-size: 1.3rem;
margin: 10px 4px;
border-radius: 7px;
`
;
const StyledModal = styled(Modal)`
  position: relative;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  padding: 20px;
  background: linear-gradient(145deg, #292929, #1e1e1e); // Example gradient
  color: #fff; // Adjust text color
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  color: #ff8c00; // Example color
  text-align: center;
  text-shadow: 1px 1px 2px black; // Subtle text shadow
  margin-bottom: 15px;
`;

const Balance = styled.div`
  font-size: 2.5rem;
  color: #ffd700; // Gold color for balance
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::before {
    content: '🐉'; // Dragon emoji as an icon
    font-size: 3rem;
    margin-bottom: 10px;
  }
`;
const StyledLink = styled(NavLink)`
  display: block;
  border: 2px solid ${(props) => props.theme.color.white};
  padding: 10px;
  color: ${(props) => props.theme.color.white};
  text-align: center;
  text-decoration: none;
  font-size: 1.3rem;
  margin: 10px 4px;
  border-radius: 7px;
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF; // White text color
  font-size: 1.2rem; // Adjust the font size as needed
  &:hover {
    opacity: 0.8;
  }
`;

export default AccountModal
